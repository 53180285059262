.customAccordion {
	/* margin-top: 4px !important; 
	/* margin-bottom: 4px !important; */
	/* box-shadow: 0 0 4px 0px #e0e0e0 !important; */
	box-shadow: none !important;
	border: 1px solid #d2d6da !important;
	border-radius: 5px !important;
}

.customAccordion::before {
	display: none !important;
}

.customAccordion.Mui-expanded {
	/* margin-top: 0 !important; */
}
